import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import Check, { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import DatePicker from "../../components/Input/components/DatePicker/DatePicker";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

const EarlyReturnScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onIncapacityEndDateChange = (newIncapacityEndDate: string) => {
        updateCheck({ newIncapacityEndDate });
    }

    const onWorkerAgreementChange = (workerDisagreement: boolean) => {
        updateCheck({ workerDisagreement });
    }

    const onConfirmClick = () => {
        const { history } = props;

        if (check.workerPhoneNumber) {
            history.push(Path.COMMENTS);
        }

        else {
            history.push(Path.PATIENT_SIGNATURE);
        }
    }

    return (
        <Dialog>
            <h1>{translate("earlyWorkerReturn")}</h1>

            <DialogBody alignment="start">
                <DatePicker
                    label="earlyReturnLabel"
                    initialValue={check.newIncapacityEndDate}
                    min={new Date()}
                    max={new Date((new Date().setTime(Check.parseDate(check.incapacityEndDate!).getTime() - (24 * 60 * 60 * 1000))))}
                    onChange={onIncapacityEndDateChange}
                />

                <Options
                    label="workerDisagreementLabel"
                    onChange={onWorkerAgreementChange}
                    selectedValue={check.workerDisagreement}
                    options={[
                        { value: true, text: "yes" },
                        { value: false, text: "no" }
                    ]}
                />
            </DialogBody>

            <DialogControls
                canProceedToNext={check.newIncapacityEndDate !== undefined && check.workerDisagreement !== undefined}
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(EarlyReturnScreen);