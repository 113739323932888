import React, { Component } from "react";
import Check from "../../../models/types/Check";
import API from "../../../network/ API";
import { CheckContext, defaultCheck } from "./CheckContext";

export default class CheckContextProvider extends Component<{}, { check: Check | undefined }> {

    state = {
        check: defaultCheck
    }

    private _updateCheck = (fields: Partial<Check>) => {
        this.setState({
            check: new Check({ ...this.state.check, ...fields })
        }, () => {
            console.log(this.state.check)
        });
    }

    private _sendResults = async () => {
        const { check } = this.state;

        if (!check || !check.id) {
            return;
        }

        return await API.sendCheckResult(check);
    }

    render() {
        return (
            <CheckContext.Provider value={{ check: this.state.check, updateCheck: this._updateCheck, sendResults: this._sendResults }}>
                {this.props.children}
            </CheckContext.Provider>
        );
    }
}