import React, { memo, useState } from "react";
import Path from "../../../models/constants/Path";
import Check, { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import DatePicker from "../../components/Input/components/DatePicker/DatePicker";
import TextArea from "../../components/Input/components/TextArea/TextArea";
import TimePicker from "../../components/Input/components/TimePicker/TimePicker";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import "./WorkAccidentInformationScreenStyle.scss";
import { subDays } from "date-fns";


function parse(str: string) {
    try {
        const [date, month, year] = str.split('/').map(n => parseInt(n))
        return new Date(year, month - 1, date)
    }
    catch
    {
        return new Date()
    }
}

const WorkAccidentInformationScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();
    const [dateError, setDateError] = useState(false);

    const onAccidentDateChange = (accidentDate: string) => {

        const parsedAccidentDate = parse(accidentDate);
        const parsedStartDate = parse(check.incapacityStartDate);

        if (parsedAccidentDate > parsedStartDate) {
            setDateError(true);
        }
        else if (parsedAccidentDate < subDays(new Date(), 45)) {
            setDateError(true);
        }
        else {
            setDateError(false);
        }
        updateCheck({ accidentDate });
    }

    const onAccidentTimeChange = (accidentTime: string) => {
        updateCheck({ accidentTime });
    }

    const onAccidentDescriptionChange = (accidentDescription: string) => {
        updateCheck({ accidentDescription });
    }

    const canProceed = () => {
        if (dateError || !check.accidentDate || !check.accidentTime || !check.accidentDescription) {
            return false;
        }

        /*
        if (new Date(parse(check.accidentDate)) > new Date(check.incapacityStartDate) && !dateError) {
            setDateError(true);
            return false;
        }

        if (new Date(parse(check.accidentDate)) < subDays(new Date(), 45) && !dateError) {
            setDateError(true);
            return false;
        }
        */
        return true;
    }

    const onNextClick = () => {
        const { history } = props;
        history.push(Path.INJURY_INFORMATION);
    }

    return (
        <Dialog>
            <h1>{translate("accidentDescription")}</h1>

            <DialogBody alignment='start'>
                <DatePicker
                    propsError={dateError}
                    errorMessage={translate("dateOutOfRange")}
                    label="accidentDateLabel"
                    onChange={onAccidentDateChange}
                    initialValue={check.accidentDate}
                    min={subDays(new Date(), 45)}
                    max={parse(check.incapacityStartDate)}
                />

                <TimePicker
                    label="accidentTimeLabel"
                    onChange={onAccidentTimeChange}
                    initialValue={check.accidentTime}
                />

                <TextArea
                    maxLength={110}
                    label="accidentDescriptionLabel"
                    placeholder="accidentDescription"
                    onChange={onAccidentDescriptionChange}
                    initialValue={check.accidentDescription}
                />
            </DialogBody>

            <DialogControls
                canProceedToNext={canProceed()}
                onNextClick={onNextClick}
            />
        </Dialog>
    );
}

export default memo(WorkAccidentInformationScreen);