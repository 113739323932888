import React, { ChangeEvent } from "react";
import TranslationView from "../../../TranslationView/TranslationView";
import Input from "../../Input";
import './ImagePickerStyle.scss';

export default class ImagePicker extends Input {
    
    protected _onChange(event : ChangeEvent<HTMLInputElement>) {
        const picture = event.target.files?.[0];

        if (!picture) {
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsDataURL(picture);

        fileReader.onloadend = (event) => {
            if (!fileReader.result) {
                return;
            }

            const value = fileReader.result.toString();
            const error = this._getDerivedErrorFromValue(value);

            this.setState({ value, error });

            if (!error) {
                this.props.onChange?.(value);
            }
        }
    }

    protected _getDerivedErrorFromValue(path : string) {
        return undefined;
    }

    render() {
        const { label } = this.props;
        const { value } = this.state;

        return (
            <div className='line'>
                <label htmlFor='image-picker'>
                    {label &&
                        <TranslationView>
                            {label}
                        </TranslationView>
                    }

                    <div className="picture-file">
                        {value && 
                            <img className='preview-file' src={value} alt='preview'/>
                        }
                    </div>
                </label>

                <input 
                    id='image-picker' 
                    type='file' 
                    onChange={this._onChange} 
                    accept='image/*,.jpg,.jpeg,.png'
                    style={{ display : "none" }} />
            </div>
        );
    }
}