import { createContext, useContext } from 'react';
import Answer from '../../../models/constants/Answer';
import Certificate from '../../../models/constants/Certificate';
import CheckLocation from '../../../models/constants/CheckLocation';
import Incapacity from '../../../models/constants/Incapacity';
import Percentage from '../../../models/constants/Percentage';
import Check from '../../../models/types/Check';

export interface CheckContextValue {
    check: Check,
    updateCheck: (fields: Partial<Check>) => void,
    sendResults: () => Promise<Response | undefined>;
}

export const defaultCheck: Check = new Check({
    id: "",

    // Worker information
    workerFirstName: "",
    workerLastName: "",
    workerPhoneNumber: undefined,
    companyCommercialName: "",

    // Doctor information
    doctorFirstName: "",
    doctorLastName: "",
    doctorMainLanguage: "",

    // Incapacity information
    checkDate: "",
    incapacityType: Incapacity.ILLNESS,
    incapacityStartDate: "",
    incapacityEndDate: undefined,
    incapacityPercentage: Percentage.UNKNOWN,
    incapacityValidatedToday: undefined,
    certificateType: Certificate.UNKNOWN,

    // Check information
    location: CheckLocation.OFFICE,
    secondCheckNeeded: false,
    secondCheckDate: undefined,
    secondCheckTime: undefined,
    isSecondCheck: false,

    // Work accident information
    accidentDate: undefined,
    accidentTime: undefined,
    accidentDescription: undefined,

    initialInjuryDescription: "",
    injuryDescription: "",
    injuryDifference: false,

    plausibility: Answer.NO,

    // Result information
    didWorkerShowUp: false,
    result: undefined,
    newIncapacityEndDate: undefined,
    workerDisagreement: false,

    resultCommentMedicheck: undefined,
    resultCommentWorker: undefined,
    resultWorkerSignature: undefined,
    secondCheckConvocation: undefined,

    convocationShouldBeReported: false
});

export const CheckContext = createContext<CheckContextValue>({
    check: defaultCheck,
    updateCheck: () => { },
    sendResults: async () => undefined
});

export function useCheck() {
    return useContext(CheckContext);
}
