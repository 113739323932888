import React, { memo } from "react";
import Certificate from "../../../models/constants/Certificate";
import Path from "../../../models/constants/Path";
import Percentage from "../../../models/constants/Percentage";
import Check, { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import DatePicker from "../../components/Input/components/DatePicker/DatePicker";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

const CertificateInformationScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onStartDateChange = (incapacityStartDate: string) => {
        updateCheck({ incapacityStartDate });
    }

    const onEndDateChange = (incapacityEndDate: string) => {
        updateCheck({ incapacityEndDate });
    }

    const onIncapacityPercentageChange = (incapacityPercentage: Percentage) => {
        updateCheck({ incapacityPercentage });
    }

    const onCertificateTypeChange = (certificateType: Certificate) => {
        updateCheck({ certificateType });
    }

    const canProceed = () => {
        if (!check.incapacityStartDate || !check.incapacityEndDate ||
            check.incapacityPercentage === undefined || check.certificateType === -1 || check.incapacityPercentage === -1 || check.certificateType === undefined) {
            return false;
        }

        return true;
    }

    const onNextClick = () => {
        const { history } = props;

        if (check.isWorkAccident()) {
            history.push(Path.ACCIDENT_INFORMATION);
        }

        else {
            history.push(Path.RESULT);
        }
    }

    return (
        <Dialog>
            <h1>{translate("certificateInformation")}</h1>

            <DialogBody alignment='start'>
                <DatePicker
                    label="incapacityStartDateLabel"
                    initialValue={check.incapacityStartDate}
                    onChange={onStartDateChange}
                />

                <DatePicker
                    label="incapacityEndDateLabel"
                    min={Check.parseDate(check.incapacityStartDate)}
                    initialValue={check.incapacityEndDate}
                    onChange={onEndDateChange}
                />

                <Options
                    label="incapacityPercentageLabel"
                    selectedValue={check.incapacityPercentage}
                    options={[
                        { value: Percentage.HUNDRED, text: "hundredPercent" },
                        { value: Percentage.FIFTY, text: "fiftyPercent" },
                        { value: Percentage.ZERO, text: "zeroPercent" }
                    ]}
                    onChange={onIncapacityPercentageChange}
                />

                <Options
                    label="certificateTypeLabel"
                    selectedValue={check.certificateType}
                    options={[
                        { value: Certificate.FIRST, text: "firstCertificate" },
                        { value: Certificate.PROLONGATION, text: "prolongationCertificate" },
                        { value: Certificate.RELAPSE, text: "relapseCertificate" }
                    ]}
                    onChange={onCertificateTypeChange}
                />
            </DialogBody>

            <DialogControls
                canProceedToNext={canProceed()}
                onNextClick={onNextClick}
            />
        </Dialog>
    );
}

export default memo(CertificateInformationScreen);