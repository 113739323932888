import { createBrowserHistory } from 'history';
import React, { Component } from "react";
import { Route, Router, Switch } from 'react-router-dom';
import Path from '../models/constants/Path';
import './AppStyle.scss';
import CheckContextProvider from './components/CheckContext/CheckContextProvider';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LocalizedContextProvider from './components/LocalizedContext/LocalizedContextProvider';
import OverlayWrapper from './components/OverlayWrapper/OverlayWrapper';
import CertificationInformationScreen from './modules/CertificateInformationScreen/CertificateInformationScreen';
import CertificatePossessionScreen from "./modules/CertificatePossessionScreen/CertificatePossesionScreen";
import CheckReschedulingScreen from './modules/CheckReschedulingScreen/CheckReschedulingScreen';
import CommentScreen from './modules/CommentScreen/CommentScreen';
import CompletionScreen from './modules/CompletionScreen/CompletionScreen';
import ConvocationLetterScreen from './modules/ConvocationLetterScreen/ConvocationLetterScreen';
import EarlyReturnScreen from './modules/EarlyReturnScreen/EarlyReturnScreen';
import IncapacityConfirmationScreen from './modules/IncapacityConfirmationScreen/IncapacityConfirmationScreen';
import IndependenceScreen from './modules/IndependenceScreen/IndependenceScreen';
import InitialInjuryInformationScreen from './modules/InitialInjuryInformationScreen/InitialInjuryInformationScreen';
import InjuryInformationScreen from './modules/InjuryInformationScreen/InjuryInformationScreen';
import LandingScreen from './modules/LandingScreen/LandingScreen';
import NoResultCertificateScreen from './modules/NoCertificateResultScreen/NoCertificateResultScreen';
import NotFoundScreen from './modules/NotFoundScreen/NotFoundScreen';
import PatientSignatureScreen from './modules/PatientSignatureScreen/PatientSignatureScreen';
import ReturnDateResultScreen from './modules/ReturnDateResultScreen/ReturnDateResultScreen';
import SummaryScreen from './modules/SummaryScreen/SummaryScreen';
import WorkAccidentInformationScreen from './modules/WorkAccidentInformationScreen/WorkAccidentInformationScreen';

export default class App extends Component {
    
    static _history = createBrowserHistory();

    render() {
        return (
            <CheckContextProvider>
                <LocalizedContextProvider initialLanguage='fr' supportedLanguages={['fr', 'en', 'nl']} translations={require('./translations/translations.json')}>
                    <div id='app-root'>
                        <Header />

                        <Router history={App._history}>
                            <Switch>
                                {/* First screen doctors will see, where they can indicate wether or not a patient was present */}
                                <Route path='/ml/:id' component={LandingScreen} />

                                <Route path={Path.INDEPENDENCE} component={IndependenceScreen} />

                                <Route path={Path.CERTIFICATE_POSSESSION} component={CertificatePossessionScreen} />

                                <Route path={Path.CERTIFICATE_INFORMATION} component={CertificationInformationScreen} />

                                <Route path={Path.ACCIDENT_INFORMATION} component={WorkAccidentInformationScreen} />

                                <Route path={Path.INJURY_INFORMATION} component={InjuryInformationScreen} />

                                <Route path={Path.INITIAL_INJURY_INFORMATION} component={InitialInjuryInformationScreen} />

                                <Route path={Path.CHECK_RESCHEDULING} component={CheckReschedulingScreen} />

                                <Route path={Path.CONVOCATION_LETTER} component={ConvocationLetterScreen} />

                                <Route path={Path.COMMENTS} component={CommentScreen} />

                                <Route path={Path.PATIENT_SIGNATURE} component={PatientSignatureScreen} />

                                <Route path={Path.RESULT} component={ReturnDateResultScreen} />

                                <Route path={Path.NO_CERTIFICATE} component={NoResultCertificateScreen} />

                                <Route path={Path.EARLY_RETURN} component={EarlyReturnScreen} />

                                <Route path={Path.INCAPACITY_CONFIRMATION} component={IncapacityConfirmationScreen} />

                                <Route path={Path.SUMMARY} component={SummaryScreen} />
 
                                <Route path={Path.THANKS} component={CompletionScreen} />

                                {/* 404 page for any wrong URLs */}
                                <Route path='/' component={NotFoundScreen} />
                            </Switch>
                        </Router>

                        <Footer />

                        <OverlayWrapper />
                    </div>
                </LocalizedContextProvider>
            </CheckContextProvider>
        );
    }
}