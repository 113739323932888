import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as MaterialDatePicker, LocalizationProvider } from '@material-ui/pickers';
import { isValid } from "date-fns";
import React from "react";
import Check from '../../../../../models/types/Check';
import Input from '../../Input';
import Label from '../Label/Label';
import DatePickerProps from "./DatePickerProps";
import { TextField } from '@material-ui/core';

export default class DatePicker extends Input<DatePickerProps> {
    static defaultDateMask = 'dd/MM/yyyy';

    protected _getDerivedErrorFromValue(value: string): string | undefined {
        const parsed = Check.parseDate(value);
        return isValid(parsed) ? undefined : 'dateFormatError';
    }

    private onInputChange = (date: Date | null, value: string | null | undefined) => {
        if (date && isValid(date)) {
            this._onChange({
                target: {
                    value: Check.formatDate(date, { locale: this.context.locale })
                }
            });
        }

        else if (value && !value.includes('_')) {
            this._onChange({
                target: {
                    value
                }
            });
        }
    }

    render() {
        const { label, min, max, propsError, errorMessage } = this.props;
        const { value, error } = this.state;
        const shownValue = value
            ? Check.parseDate(value)
            : null;

        return (
            <LocalizationProvider locale={this.context.locale} dateAdapter={DateFnsUtils}>
                <div className={propsError ? "error" : ""}>
                    {label &&
                        <Label text={label} />
                    }

                    <MaterialDatePicker
                        minDate={min}
                        maxDate={max}
                        value={shownValue}
                        inputFormat={DatePicker.defaultDateMask}
                        renderInput={(props) => <TextField error={error ? true : !!error} {...props} />}
                        toolbarPlaceholder={DatePicker.defaultDateMask.toUpperCase()}
                        onChange={this.onInputChange}
                    />

                    {propsError && errorMessage &&
                        <div>
                            {errorMessage}
                        </div>
                    }
                </div>
            </LocalizationProvider>
        );
    }
}